<template>
    <l-map
        :class="{ 'is-disable-scroll': disableScroll }"
        :center="latLngCenter"
        :zoom="zoom"
		gestureHandling
		:options="mapOptions"
        @ready="$emit('ready', $event), (map = $event)"
    >
        <!-- Content. -->
        <slot />

        <!-- Credits. -->
        <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        />
        <template v-if="fullscreenable">
            <l-control>
                <base-button
                    btn2
                    :icon="
                        isZenMode ? 'fas fa-compress-alt' : 'fas fa-expand-alt'
                    "
                    @click="SET_ZEN_MODE(!isZenMode)"
                />
            </l-control>
        </template>
    </l-map>
</template>

<script>
import { LMap, LTileLayer, LControl } from 'vue2-leaflet';
import { mapMutations, mapGetters } from 'vuex';
import { SET_ZEN_MODE } from '../store/mutations.type';
import { GestureHandling } from "leaflet-gesture-handling";

export default {
    components: {
        LMap,
        LTileLayer,
        LControl,
    },
    props: {
        lat: {
            type: Number,
            required: true,
        },
        lng: {
            type: Number,
            required: true,
        },
        zoom: {
            type: Number,
            required: true,
        },
        fullscreenable: {
            type: Boolean,
            default: true,
        },
        disableScroll: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            map: undefined,
            defaultLat: 52.1874047455997,
            defaultLng: 19.138183593750004,
			mapOptions: {
				gestureHandling:true
			}
        };
    },
    computed: {
        ...mapGetters(['isZenMode']),
        latLngCenter() {
            if (this.lat === 0 && this.lng === 0) {
                return [this.defaultLat, this.defaultLng];
            }
            return [this.lat, this.lng];
        },
    },
    watch: {
        isZenMode() {
            this.map.invalidateSize(true);
        },
        map() {
            if (this.disableScroll) {
                this.map.scrollWheelZoom.disable();
            }
        },
    },
    methods: {
        ...mapMutations({ SET_ZEN_MODE }),
    },
	mounted() {
      this.$nextTick(() => {
        L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
      });
    },
};
</script>
<style>
@media (max-width: 1000px) {
    .is-disable-scroll.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
        pointer-events: none !important;
    }
}
</style>
<style src="leaflet-gesture-handling/dist/leaflet-gesture-handling.css"></style>