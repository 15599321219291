<template>
  <div class="container mat40">
    <div class="row admin-header">
      <div class="col-12 col-md-4">
        <h1>Moje opłaty</h1>
      </div>
    </div>
    <div
      v-if="!ready"
      class="mat32"
    >
      <div class="row">
        <div class="col-12">
          <div class="admin-header__loading-state">
            Trwa wczytywanie
            <div class="mal15 lds-ellipsis">
              <div />
              <div />
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <div class="info-box">
          <h4>Inne metody płatności</h4>
          <p>Jeśli preferujesz inną metodę płatności,<br> skontaktuj się z nami <a href="tel:+48502678564" style="font-weight: bold;">502 678 564</a></p>
        </div>
        <div class="provisions" v-if="data.length > 0">
          <div v-for="provision in data" :key="provision.id" class="provision-item" :class="{expired: provision.overDate}">
            <div>
              <div class="provision-name">
                <b>{{ provision.competitionName }}</b>
              </div>
            </div>
            <div><b>{{ provision.lastCountedProvision }} zł</b></div>
            <div v-if="provision.payed">&nbsp;</div>
            <div v-else-if="provision.overDate">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0581 3.26606C12.2459 2.91131 12.754 2.91131 12.9418 3.26606L21.9418 20.2661C22.1181 20.5991 21.8767 21 21.5 21H3.49995C3.12317 21 2.88176 20.5991 3.05806 20.2661L12.0581 3.26606ZM12.5 14C12.7761 14 13 13.7761 13 13.5V9.5C13 9.22386 12.7761 9 12.5 9C12.2238 9 12 9.22386 12 9.5V13.5C12 13.7761 12.2238 14 12.5 14ZM13 17V16H12V17H13ZM20.6695 20H4.33041L12.5 4.56863L20.6695 20Z" fill="#979797"/>
              </svg>
              Termin płatności upłynął {{ provision.paymentDate.split('T')[0] }}
            </div>
            <div v-else>Termin płatności {{ provision.paymentDate.split('T')[0] }}</div>
            <div v-if="provision.payed">opłacono {{ provision.paymentDate.split('T')[0] }}</div>
            <div>&nbsp;
              <!-- <base-button btn2>Opłać szybką płatnością</base-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'PanelProvisionList',
  data() {
    return {
      ready: false,
      loading: false,
      data: [],
      errors: {},
    };
  },
  computed: {
    ...mapGetters(['user', 'operators', 'organizers', 'competitions']),
    isAdmin() {
      return this.user.roles.includes('ROLE_ADMIN')
    },
    isOperator() {
      return this.user.operator;
    },
    isOrganizer() {
      return this.user.organizer;
    },
    isModerator() {
      return (
          this.user.roles.includes('ROLE_ADMIN')
          || this.user.roles.includes('ROLE_OPERATOR')
      );
    },    
  },
  async created() {
    const url = this.isOperator ? `/operators/${this.isOperator}/provision` : `/organizers/${this.isOrganizer}/provision`
    const data = await axios({ method: 'get', url })
    this.data = data;
    this.ready = true;
  },
  methods: {}
}
</script>

<style scoped>
.admin-header {
  margin-bottom: 40px;
}
.info-box {
  padding: 24px 32px;
  background: #455A64;
  border-radius: 4px;
  margin-bottom: 24px;
}
.info-box h4 {
  margin-bottom: 16px;
}
.provision-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 24px;
  color: #455A64;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
}
.provision-item.expired {
  background: #FFF1CC;
}
.provision-item > div:nth-child(1) {
  width: 40%;
}
.provision-item > div:nth-child(2) {
  width: 10%;
  text-align: right;
}
.provision-item > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  margin-right: 32px;
}
.provision-item svg {
  margin-right: 12px;
}
@media (max-width: 1041px) {
  .info-box,
  .provision-item {
    padding: 16px;
  }
  .provision-item > div:nth-child(1) {
    width: 70%;
    margin-bottom: 12px;
  }
  .provision-item > div:nth-child(2) {
    width: 30%;
  }
  .provision-item > div:nth-child(3) {
    width: 100%;
    justify-content: flex-start;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .provision-item > div:nth-child(4) {
    width: 100%;
  }
}
</style>
